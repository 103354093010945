import { LogoutIcon, UserIcon } from '@heroicons/react/outline'
import { PersonIcon } from '../../base/icon/Person'
import { Trans } from 'next-i18next'
import { useCurrentUser, useSignOut } from '../../../auth/auth'
import { useQueryClient } from '@tanstack/react-query'
import Button from '../../base/button/Button'
import Dropdown from '../../base/dropdown/Dropdown'
import DropdownItem from '../../base/dropdown/DropdownItem'
import Link from 'next/link'

const AppBarUserMenu = () => {
    const { user } = useCurrentUser()
    const { signOut } = useSignOut()
    const queryClient = useQueryClient()

    const handleLogout = () => {
        queryClient.cancelQueries()
        signOut()
    }

    const getTitle = () => {
        if (user?.role === 'customer') {
            return <p className='text-md text-body-regular font-normal text-black'>{user.billingAddressCompanyName}</p>
        }
        const fullName = `${user?.firstName} ${user?.lastName}`
        return <p className='text-md text-body-regular font-normal text-black'>{fullName}</p>
    }

    return (
        <div className='flex items-center'>
            <Dropdown
                placement='bottom-end'
                activator={(props, ref) => {
                    return (
                        <Button as={Link} href='/app/profile' className='!p-3' {...props} ref={ref}>
                            <PersonIcon className='fill-white ' fill='white' />
                        </Button>
                        //       <div
                        //       {...props}
                        //       ref={ref}
                        //       className='py-[9.5px] px-[9.5px] rounded-[10px] shadow-primary-shadow bg-primary
                        //       flex items-center justify-center cursor-pointer'
                        //   >
                        //       <Link href='/app/profile'>
                        //           <PersonIcon className='fill-white' fill='white' />
                        //       </Link>
                        //     </div>
                    )
                }}
            >
                <div className='py-2.5 shadow-primary-shadow rounded-[10px]'>
                    <div className='flex items-center px-3 pb-2 mb-1 border-b-2'>{getTitle()}</div>
                    <DropdownItem as={Link} href='/app/profile' className='px-3' icon={<UserIcon />}>
                        <Trans i18nKey='appbar.user.profile' />
                    </DropdownItem>
                    <DropdownItem onClick={handleLogout} className='px-3' icon={<LogoutIcon />}>
                        <Trans i18nKey='appbar.user.logout' />
                    </DropdownItem>
                </div>
            </Dropdown>
        </div>
    )
}

export default AppBarUserMenu
