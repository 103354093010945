import { OrderStatus, PriceOffer } from '../../services/api/order/OrderService.types'
import { PropsWithChildren, ReactNode, useMemo } from 'react'
import { SidebarItem } from '../dashboard/sidebar/Sidebar'
import { Trans } from 'next-i18next'
import { buildClassesWithDefault } from '../../utils/StyleHelper'
import Administration from '../base/icon/AdministrationIcon'
import AppBar, { AppBarProps } from '../dashboard/app-bar/AppBar'
import Button from '../base/button/Button'
import Column from '../base/grid/Column'
import Layout, { LayoutProps } from './Layout'
import Link from 'next/link'
import Loading from '../base/loading/Loading'
import MobileSidebar from '../dashboard/sidebar/MobileSidebar'
import PostInfoCardList from '../dashboard/posts/PostInfoCardList'
import WrapperContent, { WrapperType } from '../base/wrapper-content'
import useMiddleware from '../../hooks/useMiddleware'
import useTheme from '../../hooks/useTheme'

export type PrimaryTitle = 'appbar' | 'layout'
export type DashboardLayoutProps = LayoutProps & {
    dashboardTitle?: ReactNode
    appBarContent?: ReactNode
    navigationLine?: ReactNode
    appBarProps?: AppBarProps
    primaryTitle?: PrimaryTitle
    showAppBar?: boolean
    showPosts?: boolean
    sidebar?: ReactNode
    footer?: JSX.Element
    demandStatus?: OrderStatus
    priceOffer?: PriceOffer[]
    type?: WrapperType
}
const DashboardLayout = ({
    children,
    dashboardTitle,
    appBarContent,
    navigationLine,
    appBarProps,
    showAppBar = true,
    showPosts = true,
    primaryTitle = 'appbar',
    sidebar,
    footer,
    demandStatus,
    priceOffer,
    type,
    ...props
}: PropsWithChildren<DashboardLayoutProps>) => {
    const { openedSidebar } = useTheme()
    const { isInitialized } = useMiddleware(true)
    const navigation = useMemo<SidebarItem[]>(() => {
        return [
            {
                name: <Trans i18nKey='sidebar.items.administration' />,
                icon: Administration,
                navigation: [
                    {
                        name: <Trans i18nKey='sidebar.items.users' />,
                        href: '/app/administration/users'
                        // icon: Users
                    },
                    {
                        name: <Trans i18nKey='sidebar.items.customers' />,
                        href: '/app/administration/takers'
                        // icon: Users
                    },
                    {
                        name: <Trans i18nKey='sidebar.items.cartage' />,
                        href: '/app/administration/cartage'
                        // icon: TruckIcon
                    },
                    {
                        name: <Trans i18nKey='sidebar.items.cartage-delay' />,
                        href: '/app/administration/cartage-delay'
                        // icon: ClockIcon
                    },
                    {
                        name: <Trans i18nKey='sidebar.items.cartages_and_customers' />,
                        href: '/app/administration/cartages-and-customers'
                        // icon: BookOpenIcon
                    },
                    {
                        name: <Trans i18nKey='sidebar.items.role' />,
                        href: '/app/administration/role'
                        // icon: IdentificationIcon
                    },
                    {
                        name: <Trans i18nKey='sidebar.items.product' />,
                        href: '/app/administration/product'
                        // icon: CubeIcon
                    },
                    {
                        name: <Trans i18nKey='sidebar.items.product_version' />,
                        href: '/app/administration/product-version'
                        // icon: TagIcon
                    },
                    {
                        name: <Trans i18nKey='sidebar.items.translation' />,
                        href: '/app/administration/translation'
                        // icon: LanguageIcon
                    },
                    {
                        name: <Trans i18nKey='sidebar.items.product_category' />,
                        href: '/app/administration/product-category'
                        // icon: IdentificationIcon
                    },
                    {
                        name: <Trans i18nKey='sidebar.items.enum_group' />,
                        href: '/app/administration/enum-group'
                        // icon: SwatchIcon
                    },
                    {
                        name: <Trans i18nKey='sidebar.items.auditing' />,
                        href: '/app/administration/auditing'
                        // icon: TagIcon
                    }
                ]
            }
        ]
    }, [])
    const renderAppBar = () => {
        const title = primaryTitle === 'appbar' ? dashboardTitle || props.title : ' '
        if (showAppBar) {
            return (
                <div className='sticky top-0 w-full bg-white z-10'>
                    <AppBar
                        title={title}
                        demandStatus={demandStatus}
                        priceOffer={priceOffer}
                        isNavBar={!!navigationLine}
                        {...appBarProps}
                    >
                        {appBarContent}
                    </AppBar>
                    {navigationLine}
                </div>
            )
        }
        return null
    }

    const renderContent = () => {
        return (
            <>
                <main>
                    <Column removePaddingX removePaddingY>
                        {renderAppBar()}
                        <div className='flex px-8 py-8 bg-cover bg-center'>
                            <div className={`${showPosts ? 'basis-3/4' : 'w-full'}`}>
                                <WrapperContent type={type}>{children}</WrapperContent>
                            </div>
                            {showPosts && (
                                <div className='basis-1/4'>
                                    <aside className='sticky top-0 h-[100vh] overflow-y-auto no-scrollbar'>
                                        <PostInfoCardList />
                                        <div className='sticky bottom-12  z-20 flex justify-center py-4'>
                                            <div className='flex xl:h-[40px] xl:mb-[-15px] xl:w-full xl:blur'></div>
                                            <Button
                                                as={Link}
                                                href='/app/news'
                                                variant='destructive'
                                                className='xl:fixed xl:bottom-4'
                                            >
                                                <Trans i18nKey='dashboard.demand.table.actions.show_all' />
                                            </Button>
                                        </div>
                                    </aside>
                                </div>
                            )}
                        </div>
                    </Column>
                </main>
            </>
        )
    }
    const renderLoading = () => {
        if (!isInitialized) {
            return (
                <div className='w-full min-h-screen flex justify-center items-center'>
                    <Loading className='w-24 h-24' />
                </div>
            )
        }
    }

    return (
        <Layout {...props}>
            {isInitialized && (
                <div>
                    <MobileSidebar items={navigation}>{sidebar}</MobileSidebar>
                    <div
                        className={buildClassesWithDefault(
                            {
                                'md:pl-[256px]': openedSidebar
                            },
                            'flex flex-col justify-between min-h-screen transition-all duration-300'
                        )}
                    >
                        {renderContent()}
                        {footer}
                    </div>
                </div>
            )}
            {renderLoading()}
        </Layout>
    )
}
export default DashboardLayout
