import { ChevronUpIcon } from '@heroicons/react/outline'
import { RadiusIcon } from '../../base/icon/Radius'
import { ReactScrollLinkProps } from 'react-scroll/modules/components/Link'
import { Link as ScrollLink } from 'react-scroll'
import { SidebarItem } from './Sidebar'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import { useRouter } from 'next/router'
import React, { MouseEvent, useEffect, useState } from 'react'

export type SidebarListItemProps = {
    item: SidebarItem
}

const showItemIcon = true

const SidebarListItem = ({
    item: { href, name, icon: Icon, navigation, onClick, active: isItemActive = false, scrollableLink }
}: SidebarListItemProps) => {
    const { pathname, push: routerPush, reload: routerReload } = useRouter()
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (navigation) {
            const isTakersPath =
                pathname === '/app/administration/takers' || pathname.startsWith('/app/administration/sub-takers')

            const isNavActive = navigation.some(
                navItem =>
                    (navItem.href === '/app/administration/takers' && isTakersPath) ||
                    isItemActive ||
                    pathname === navItem.href ||
                    pathname.startsWith(`${navItem.href}/`)
            )

            if (isNavActive) {
                setIsOpen(true)
            }
        }
    }, [pathname, navigation, isItemActive])

    const isActiveLink = (path: string) => {
        if (!path) {
            return false
        }

        if (
            path === '/app/administration/takers' &&
            (pathname === '/app/administration/takers' || pathname.startsWith('/app/administration/sub-takers'))
        ) {
            return true
        }

        if (isItemActive) {
            return true
        }

        return pathname === path || pathname.startsWith(`${path}/`)
    }

    const redirectTo = (link: string) => {
        if (link === pathname) {
            routerReload()
            return
        }
        routerPush(link)
    }

    const onItemClick = (event: MouseEvent<HTMLAnchorElement>) => {
        if (navigation) {
            setIsOpen(!isOpen)
        } else if (onClick) {
            onClick(event)
        }
    }

    const renderItem = () => {
        const showIcon = Icon && showItemIcon

        if (href) {
            const classes = {
                active: isActiveLink(href),
                inactive: !isActiveLink(href),
                item: true
            }
            return (
                <a
                    href={href}
                    onClick={event => {
                        event.preventDefault()
                        redirectTo(href)
                    }}
                    className={buildClassesWithDefault(classes, 'group')}
                >
                    {showIcon && (
                        <Icon className='icon' fill={isActiveLink(href) ? 'white' : 'black'} width={20} height={20} />
                    )}
                    {name}
                </a>
            )
        }
        if (scrollableLink) {
            return (
                <ScrollLink
                    className='group item inactive hover:cursor-pointer'
                    {...(scrollableLink as ReactScrollLinkProps)}
                    activeClass='active'
                >
                    {showIcon && <Icon className='icon' fill='black' />}
                    {name}
                </ScrollLink>
            )
        }
        return (
            <a
                onClick={onItemClick}
                className={`item group title mb-3 cursor-pointer text-gray-500 inactive ${
                    isOpen ? 'bg-gray-200 !text-black' : ''
                }`}
            >
                {showIcon && <Icon className='icon' fill={isActiveLink(href) ? 'black' : 'white'} />}
                <span className='flex-1'>{name}</span>
                <span
                    className={`ml-2 group-hover:opacity-100 transition-opacity duration-300 ${
                        !isOpen ? '!opacity-100' : ''
                    } ${!isOpen ? 'rotate-180' : ''}`}
                >
                    <ChevronUpIcon className='w-3.5 h-3.5' />
                </span>
            </a>
        )
    }

    return (
        <div className='group'>
            {renderItem()}
            {isOpen && navigation?.length && (
                <div className='ml-6 custom-border'>
                    {navigation.map((item, index) => {
                        return (
                            <div className='flex items-center gap-0'>
                                <RadiusIcon fill='gray' className='-mt-[8px]' />
                                <div className='!flex-1'>
                                    <SidebarListItem key={index} item={item} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default SidebarListItem
